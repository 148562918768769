<template>
  <m-widget-container class="m-video-container d-flex align-center">
    <video
      ref="video"
      :src="url"
      class="mx-auto"
      preload="auto"
      :autoplay="autoplay"
      :loop="autoplay || loop"
      :controls="controls"
      :muted="muted"
      :style="styles"
      @ended="$emit('ended')"
    />
    <div
      v-if="!url"
      class="m-video-overlay"
    >
      <v-overlay
        v-if="downloading"
        absolute
      >
        <v-container class="fill-height d-flex flex-column justify-center align-center">
          <v-progress-circular indeterminate />
          <span>Downloading and saving video locally...</span>
        </v-container>
      </v-overlay>
      <v-img
        v-if="isPreview"
        :src="require('./assets/play.png')"
        class="play-preview"
        contain
        width="50%"
        height="50%"
        @click="playVideoPreview()"
      />
    </div>
  </m-widget-container>
</template>

<script>
import { mWidgetMixin } from '../../../mixins'
export default {
  name: 'MVideo',
  mixins: [mWidgetMixin],
  props: {
    src: {
      type: String,
      default: () => 'https://www.html5rocks.com/en/tutorials/video/basics/devstories.webm'
    },
    fitWidth: {
      type: Boolean,
      default: () => true
    },
    autoplay: {
      type: Boolean,
      default: () => true
    },
    loop: {
      type: Boolean,
      default: () => true
    },
    controls: {
      type: Boolean,
      default: () => false
    },
    muted: {
      type: Boolean,
      default: () => true
    }
  },
  data: () => ({
    visible: false,
    isPreview: new URLSearchParams(window.location.search).get('preview') === 'true',
    url: null,
    downloading: undefined,
    downloadProgress: undefined
  }),
  computed: {
    styles() {
      return {
        width: this.fitWidth ? '100%' : ''
      }
    }
  },
  watch: {
    src: {
      immediate: true,
      handler() {
        this.getOrCacheVideo()
      }
    }
  },
  methods: {
    async getOrCacheVideo() {
      this.url = null
      // get-download the video from-to local storage if not previewing, and display it when done
      if (!this.isPreview) {
        if ('caches' in window) {
          const cache = await caches.open('videos')
          const cachedInstance = await cache.match(this.src)
          if (cachedInstance) {
            this.downloading = false
            const blob = await cachedInstance.blob()
            this.url = URL.createObjectURL(blob)
            return
          }
          this.downloading = true
          await cache.add(this.src)
          this.getOrCacheVideo()
        } else {
          this.url = this.src
        }
      }
    },
    playVideoPreview() {
      this.isPreview = false
      this.url = this.src
    },
    async play() {
      this.visible = true
      while (!this.$refs.video) {
        await new Promise(resolve => setTimeout(resolve, 100))
      }
      this.visible && this.$refs.video.play()
    },
    async stop() {
      while (!this.$refs.video) {
        await new Promise(resolve => setTimeout(resolve, 100))
      }
      this.visible = false
      if (this.url) {
        this.$refs.video.pause()
        this.$refs.video.currentTime = 0
        this.$refs.video.load()
      }
    }
  }
}
</script>

<style lang="sass">
  .m-video-container
    position: relative
    overflow: hidden
    .m-video-overlay
      position: absolute
      top: 0
      left: 0
      z-index: 1
      width: 100%
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      .play-preview
        cursor: pointer
</style>
